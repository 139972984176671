@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;

.function-item {
	@apply flex flex-col bg-white uppercase cursor-pointer active:animate-pop duration-200 ease-in text-primary shadow-lg items-center rounded-lg gap-2 justify-center p-4
}
::-webkit-scrollbar {
	width: 5px;
	height: 10px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
	background: linear-gradient(to right, #2dc6d2, #abe8ed);
	border-radius: 5px;
}
.pin-field {
	border: 1px solid;
	border-right: none;
	font-size: 2rem;
	height: 4rem;
	outline: none;
	text-align: center;
	transition-duration: 250ms;
	transition-property: color, border, box-shadow, transform;
	width: 4rem;
}
.pin-field:invalid + .pin-field {
	border-color: #d3d3d3 !important;
}
.pin-field:invalid {
	border-color: #d3d3d3 !important;
}
.footer-shadow {
	-webkit-box-shadow: 0px -6px 3px -2px rgba(0, 0, 0, 0.18);
	box-shadow: 0px -6px 3px -2px rgba(0, 0, 0, 0.18);
}
.loader {
	transform: rotateZ(45deg);
	perspective: 1000px;
	border-radius: 50%;
	width: 48px;
	height: 48px;
	color: #fff;
}
.loader:before,
.loader:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
	height: inherit;
	border-radius: 50%;
	transform: rotateX(70deg);
	animation: 1s spin linear infinite;
}
.loader:after {
	color: #ff3d00;
	transform: rotateY(70deg);
	animation-delay: 0.4s;
}

@keyframes rotate {
	0% {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
}

@keyframes rotateccw {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}

@keyframes spin {
	0%,
	100% {
		box-shadow: 0.2em 0px 0 0px currentcolor;
	}
	12% {
		box-shadow: 0.2em 0.2em 0 0 currentcolor;
	}
	25% {
		box-shadow: 0 0.2em 0 0px currentcolor;
	}
	37% {
		box-shadow: -0.2em 0.2em 0 0 currentcolor;
	}
	50% {
		box-shadow: -0.2em 0 0 0 currentcolor;
	}
	62% {
		box-shadow: -0.2em -0.2em 0 0 currentcolor;
	}
	75% {
		box-shadow: 0px -0.2em 0 0 currentcolor;
	}
	87% {
		box-shadow: 0.2em -0.2em 0 0 currentcolor;
	}
}

.blinking {
	animation-name: blinking;
	animation-duration: 1s;
	animation-iteration-count: 100;
}
@keyframes blinking {
	50% {
		border-color: #ffb07c !important;
		background-color: #ffb07c;
	}
}
.breaking {
	background-image: linear-gradient(
		45deg,
		#ffcc01 25%,
		#e0e0e0 25%,
		#e0e0e0 50%,
		#ffcc01 50%,
		#ffcc01 75%,
		#e0e0e0 75%,
		#e0e0e0 100%
	);
	background-size: 10px 10px;
	width: 100% !important;
	left: 0px !important;
	z-index: 89 !important;
}

.holiday {
	background-image: linear-gradient(
		45deg,
		#d0edfb 25%,
		#e0e0e0 25%,
		#e0e0e0 50%,
		#d0edfb 50%,
		#d0edfb 75%,
		#e0e0e0 75%,
		#e0e0e0 100%
	);
	background-size: 10px 10px;
	width: 100% !important;
	left: 0px !important;
	z-index: 89 !important;
}

.ribbon-left-corner {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	--antd-wave-shadow-color: #323dad;
	--scroll-bar: 0;
	--viewport-width: 2000px;
	--detail-view-width: 32vw;
	--base-gradient-color: #323dad;
	--second-gradient-color: #2d73d2;
	-webkit-font-smoothing: antialiased;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	cursor: pointer;
	font-size: 14px;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum', 'tnum';
	line-height: 22px;
	white-space: nowrap;
	font-weight: bold;
	position: absolute;
	top: 100%;
	width: 8px;
	height: 8px;
	color: currentColor;
	border: 4px solid;
	transform: scaleY(0.75);
	transform-origin: top;
	box-sizing: border-box;
	outline: none;
	left: 0;
	border-color: currentcolor currentcolor transparent transparent;
}
.ribbon-left {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	--antd-wave-shadow-color: #323dad;
	--scroll-bar: 0;
	--viewport-width: 2000px;
	--detail-view-width: 32vw;
	--base-gradient-color: #323dad;
	--second-gradient-color: #2d73d2;
	-webkit-font-smoothing: antialiased;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	cursor: pointer;
	margin: 0;
	font-size: 14px;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum', 'tnum';
	position: absolute;
	top: -14px;
	height: 22px;
	padding: 0 8px;
	color: #fff;
	line-height: 22px;
	white-space: nowrap;
	border-radius: 2px;
	box-sizing: border-box;
	outline: none;
	left: -8px;
	border-bottom-right-radius: 0;
	font-weight: bold;
}
.ribbon-left-corner:after {
	position: absolute;
	top: -4px;
	left: -4px;
	width: inherit;
	height: inherit;
	color: rgba(0, 0, 0, 0.25);
	border: inherit;
	content: '';
}

.ribbon-corner:after {
	position: absolute;
	top: -4px;
	left: -4px;
	width: inherit;
	height: inherit;
	color: rgba(0, 0, 0, 0.25);
	border: inherit;
	content: '';
}

.ribbon-corner {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	--antd-wave-shadow-color: #323dad;
	--scroll-bar: 0;
	--viewport-width: 2000px;
	--detail-view-width: 32vw;
	--base-gradient-color: #323dad;
	--second-gradient-color: #2d73d2;
	-webkit-font-smoothing: antialiased;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	cursor: pointer;
	font-size: 14px;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum', 'tnum';
	line-height: 22px;
	white-space: nowrap;
	font-weight: bold;
	position: absolute;
	top: 100%;
	width: 8px;
	height: 8px;
	color: currentColor;
	border: 4px solid;
	transform: scaleY(0.75);
	transform-origin: top;
	box-sizing: border-box;
	outline: none;
	right: 0;
	border-color: currentColor transparent transparent currentColor;
}
.ribbon {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	--antd-wave-shadow-color: #323dad;
	--scroll-bar: 0;
	--viewport-width: 2000px;
	--detail-view-width: 32vw;
	--base-gradient-color: #323dad;
	--second-gradient-color: #2d73d2;
	-webkit-font-smoothing: antialiased;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	cursor: pointer;
	margin: 0;
	font-size: 14px;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum', 'tnum';
	position: absolute;
	top: -8px;
	height: 22px;
	padding: 0 8px;
	color: #fff;
	line-height: 22px;
	white-space: nowrap;
	border-radius: 2px;
	box-sizing: border-box;
	outline: none;
	right: -8px;
	border-bottom-right-radius: 0;
	font-weight: bold;
}

.ReactModal__Content {
	opacity: 0;
	transform: translateX(100%);
	transition: transform 0.5s ease-in-out;
}

.ReactModal__Content--after-open {
	opacity: 1;
	transform: translateX(0px);
}

.ReactModal__Content--before-close {
	opacity: 0;
	transform: translateX(100%);
}

.rbc-btn {
	color: inherit;
	font: inherit;
	margin: 0;
}
.ant-ribbon-wrapper {
	height: 100%;
	width: 100%;
}
.rbc-event {
	z-index: 91;
	display: flex!;
	flex-direction: column;
}
.out-working-time-slot {
	border: none !important;
	pointer-events: none;
	background-size: cover;
}
button.rbc-btn {
	overflow: visible;
	text-transform: none;
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled].rbc-btn {
	cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.rbc-calendar {
	box-sizing: border-box;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
	box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.rbc-rtl {
	direction: rtl;
}

.rbc-off-range {
	color: #999999;
}

.rbc-off-range-bg {
	background: #e6e6e6;
}

.rbc-header {
	overflow: hidden;
	flex: 1 0 0%;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	font-size: 90%;
	min-height: 0;
}
.rbc-header + .rbc-header {
	border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-header + .rbc-header {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
	color: inherit;
	text-decoration: none;
}

.rbc-row-content {
	position: relative;
	user-select: none;
	-webkit-user-select: none;
	z-index: 4;
}

.rbc-row-content-scrollable {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container {
	height: 100%;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.rbc-row-content-scrollable
	.rbc-row-content-scroll-container::-webkit-scrollbar {
	display: none;
}

.rbc-today {
	background-color: white;
}

.rbc-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
	flex-grow: 1;
	padding: 0 10px;
	text-align: center;
}
.rbc-toolbar button {
	color: #373a3c;
	display: inline-block;
	margin: 0;
	text-align: center;
	vertical-align: middle;
	background: none;
	background-image: none;
	border: 1px solid #ccc;
	padding: 0.375rem 1rem;
	border-radius: 4px;
	line-height: normal;
	white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
	background-image: none;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	background-color: #e6e6e6;
	border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
	color: #373a3c;
	background-color: #d4d4d4;
	border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
	color: #373a3c;
	background-color: #e6e6e6;
	border-color: #adadad;
}
.rbc-toolbar button:hover {
	color: #373a3c;
	background-color: #e6e6e6;
	border-color: #adadad;
}

.rbc-btn-group {
	display: inline-block;
	white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
	border-radius: 4px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
	border-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
	border-radius: 0;
}
.rbc-btn-group button + button {
	margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
	margin-left: 0;
	margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
	margin-left: 10px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
	border: none;
	box-sizing: border-box;
	box-shadow: none;
	margin: 0;
	background-color: #3174ad;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	width: 100%;
	text-align: left;
}
.rbc-slot-selecting .rbc-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-slot-selecting .rbc-background-event {
	cursor: inherit;
	pointer-events: none;
}
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
	background-color: #265985;
}
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
	outline: 5px auto #3b99fc;
}

.rbc-event-label {
	display: none;
	font-size: 90%;
	width: 100%;
	color: black;
	margin-bottom: 0px;
}

.rbc-event-overlaps {
	box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.rbc-event-continues-later {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.rbc-row {
	display: flex;
	flex-direction: row;
}

.rbc-row-segment {
	padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
	background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
	background-color: rgba(255, 255, 255, 0.3);
	z-index: 4;
	font-weight: bold;
	font-size: 85%;
	height: auto;
	line-height: normal;
}

.rbc-month-view {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 0 0;
	width: 100%;
	user-select: none;
	-webkit-user-select: none;
	height: 100%;
}

.rbc-month-header {
	display: flex;
	flex-direction: row;
}

.rbc-month-row {
	display: flex;
	position: relative;
	flex-direction: column;
	flex: 1 0 0;
	flex-basis: 0px;
	overflow: hidden;
	height: 100%;
}
.rbc-month-row + .rbc-month-row {
	border-top: 1px solid #ddd;
}

.rbc-date-cell {
	flex: 1 1 0;
	min-width: 0;
	padding-right: 5px;
	text-align: right;
}
.rbc-date-cell.rbc-now {
	font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
	color: inherit;
	text-decoration: none;
}

.rbc-row-bg {
	display: flex;
	flex-direction: row;
	flex: 1 0 0;
	overflow: hidden;
}

.rbc-day-bg {
	flex: 1 0 0%;
}
.rbc-day-bg + .rbc-day-bg {
	border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}

.rbc-overlay {
	position: absolute;
	z-index: 5;
	border: 1px solid #e5e5e5;
	background-color: #fff;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
	padding: 10px;
}
.rbc-overlay > * + * {
	margin-top: 1px;
}

.rbc-overlay-header {
	border-bottom: 1px solid #e5e5e5;
	margin: -10px -10px 5px -10px;
	padding: 2px 10px;
}

.rbc-agenda-view {
	display: flex;
	flex-direction: column;
	flex: 1 0 0;
	overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
	width: 100%;
	border: 1px solid #ddd;
	border-spacing: 0;
	border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
	padding: 5px 10px;
	vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
	padding-left: 15px;
	padding-right: 15px;
	text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
	border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
	border-top: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
	padding: 3px 5px;
	text-align: left;
	border-bottom: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
	text-align: right;
}

.rbc-agenda-time-cell {
	text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
	content: ' »';
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
	content: '« ';
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
	white-space: nowrap;
}

.rbc-agenda-event-cell {
	width: 100%;
}

.rbc-time-column {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.rbc-timeslot-group {
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-flow: column nowrap;
}
.rbc-time-gutter {
	z-index: 30 !important;
}

.rbc-time-gutter > .rbc-timeslot-group > div > .time-label {
	display: none;
}
.rbc-time-gutter > .rbc-timeslot-group > .time-slot {
	border: none;
}
.time-slot {
	height: 25px;
	border-bottom: 1px solid rgba(28, 110, 164, 0.08);
	border-right: 1px solid rgba(28, 110, 164, 0.08);
	z-index: 89;
}
.time-slot:hover {
	background-color: #d0edfb;
}
.time-slot:hover > .time-label {
	display: block;
}
.time-slot:hover > .time-label > h5 {
	color: blue;
}
.rbc-time-gutter > .rbc-timeslot-group > div:nth-child(1) > .time-label {
	display: block;
}
.rbc-time-gutter > .rbc-timeslot-group > div:nth-child(1) > .time-label > a {
	display: none;
}
.time-label {
	display: none;
	z-index: inherit;
	font-weight: bold;
}

.rbc-time-gutter,
.rbc-header-gutter {
	flex: none;
}

.rbc-label {
	padding: 0 5px;
}

.rbc-day-slot {
	position: relative;
	z-index: 20;
}
.rbc-day-slot .rbc-events-container {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	margin-right: 0px;
	top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-rtl {
	left: 10px;
	right: 0;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
	border: 1px solid #265985;
	display: flex;
	max-height: 100%;
	min-height: 20px;
	align-items: flex-start;
	overflow: hidden;
	position: absolute;
}
.rbc-day-slot .rbc-background-event {
	opacity: 0.75;
}
.rbc-day-slot .rbc-event-label {
	flex: none;
	padding-right: 5px;
	width: auto;
}
.rbc-day-slot .rbc-event-content {
	width: 100%;
	flex: 1 1 0;
	word-wrap: break-word;
	line-height: 1;
	height: 100%;
	min-height: 1em;
	font-size: large;
	font-weight: 600;
	color: black;
}
.rbc-day-slot .rbc-time-slot {
	border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
	position: sticky;
	left: 0;
	background-color: white;
	border-right: 1px solid #ddd;
	z-index: 10;
	margin-right: -1px;
}

.rbc-time-view-resources .rbc-time-header {
	overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
	min-width: auto;
	flex: 1 0 0;
	flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
	display: none;
}

.rbc-time-view-resources .rbc-day-slot {
	min-width: 100px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
	width: 100px;
	flex: 1 1 0;
	flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
	margin-left: -1px;
}

.rbc-time-slot {
	height: 10px;
	z-index: 90;
	flex: 1 0 0;
}
.rbc-time-slot.rbc-now {
	font-weight: bold;
}

.rbc-day-header {
	text-align: center;
}

.rbc-slot-selection {
	z-index: 10;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	font-size: 75%;
	width: 100%;
	padding: 3px;
}

.rbc-slot-selecting {
	cursor: move;
}
.ant-space .ant-space-vertical {
	background-color: transparent !important;
}

.rbc-time-view {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
	white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
	display: none;
	box-sizing: content-box;
	width: 100%;
	height: 100%;
	position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
	border-left: 1px solid #ddd;
}
.rbc-time-view .rbc-allday-events {
	position: relative;
	z-index: 4;
}
.rbc-time-view .rbc-row {
	box-sizing: border-box;
	min-height: 20px;
}

.rbc-time-header {
	display: flex;
	flex: 0 0 auto;
	flex-direction: row;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
	border-right-width: 0;
	border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
	border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
	display: none;
}

.rbc-time-header-content {
	flex: 1;
	display: flex;
	min-width: 0;
	flex-direction: column;
}
.rbc-time-header.rbc-overflowing > div.rbc-label.rbc-time-header-gutter {
	border-right: none;
}
.rbc-rtl .rbc-time-header-content {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
	flex-shrink: 0;
}

.rbc-time-content {
	display: flex;
	flex: 1 0 0%;
	align-items: flex-start;
	width: 100%;
	border-top: 2px solid #ddd;
	overflow-y: auto;
	position: relative;
}
.rbc-time-content > .rbc-time-gutter {
	flex: none;
}
.rbc-time-content > * + * > * {
	border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-content > * + * > * {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
	width: 100%;
	user-select: none;
	-webkit-user-select: none;
}

.rbc-current-time-indicator {
	position: absolute;
	z-index: 93;
	left: 0;
	right: 0;
	height: 3px;
	background-color: red;
	pointer-events: none;
}

.pop-over {
	flex: 1;
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: 3px;
}

.heightCalendar {
	height: calc((100vh - 140px) - 8px);
}

@media only screen and (max-width: 849px) {
	.heightCalendar {
		height: calc((100vh - 229px) - 8px);
	}
}

@media only screen and (max-width: 767px) {
	.heightCalendar {
		height: calc((100vh - 275px) - 8px);
	}
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.cb-input:checked ~ .cb-mask {
	@apply !text-opacity-100;
	animation: cb-animate 1s cubic-bezier(0, 0, 0.2, 1) 1;
}
@keyframes cb-animate {
	0%,
	100% {
		@apply transform scale-100;
	}
	50% {
		@apply transform scale-110 text-opacity-0;
	}
}
.rtl-grid {
	direction: rtl;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
.prose :where(thead th:first-child):not(:where([class~='not-prose'] *)) {
	padding-left: 10px;
}
.prose :where(tbody td:first-child):not(:where([class~='not-prose'] *)) {
	padding-left: 10px;
}
.prose :where(thead th:last-child):not(:where([class~='not-prose'] *)) {
	padding-right: 10px;
}
.prose :where(tbody td:last-child):not(:where([class~='not-prose'] *)) {
	padding-right: 10px;
}
.call-button-animation::before,
.call-button-animation::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #00d40b;
	border-radius: 100%;
	z-index: -1;
	opacity: 0.7;
}
.call-button-animation::before {
	animation: pulse2 2s ease-out infinite;
}
.call-button-animation::after {
	animation: pulse2 2s 1s ease-out infinite;
}
@keyframes pulse2 {
	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

code {
	display: inline-block;
	padding: 0.25rem 0.5rem;
}
.pin-field-container {
	display: grid;
	grid-auto-columns: max-content;
	grid-auto-flow: column;
	justify-content: center;
}
.pin-field {
	border: 1px solid #d3d3d3;
	border-right: none;
	font-size: 2rem;
	height: 4rem;
	outline: none;
	text-align: center;
	transition-duration: 250ms;
	transition-property: color, border, box-shadow, transform;
	width: 4rem;
}
.pin-field:first-of-type {
	border-radius: 0.5rem 0 0 0.5rem;
}
.pin-field:last-of-type {
	border-radius: 0 0.5rem 0.5rem 0;
	border-right: 1px solid #d3d3d3;
}
.pin-field:focus {
	border-color: #686de0;
	box-shadow: 0 0 0.25rem rgba(104, 109, 224, 0.5);
	outline: none;
}
.pin-field:focus + .pin-field {
	border-left-color: #686de0;
}
.pin-field:invalid {
	animation: shake 5 linear 75ms;
	border-color: #dc3545;
	box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.pin-field:invalid + .pin-field {
	border-left-color: #dc3545;
}
.pin-field.complete {
	border-color: #6ab04c;
	color: #6ab04c;
}
.pin-field.complete[disabled] {
	background: rgba(106, 176, 76, 0.1);
	cursor: not-allowed;
	opacity: 0.5;
}
.pin-field.complete + .pin-field {
	border-left-color: #6ab04c;
}
@keyframes shake {
	from {
		transform: translateY(-5%);
	}
	to {
		transform: translateY(5%);
	}
}

.btn-outline:hover {
	color: #686de0 !important;
}

.rc-pop-up .rc-time-picker-panel-select li {
	font-size: large;
	height: 45px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.rc-pop-up .rc-time-picker-panel-inner {
	width: 30rem;
	height: 20rem;
}

.rc-pop-up .rc-time-picker-panel-select ul {
	width: 100%;
}

.rc-pop-up .rc-time-picker-panel-select {
	width: 10rem;
	border-radius: 0.5rem;
	max-height: 17.5rem;
}

.rc-picker .rc-time-picker-input {
	height: 45px;
	/* font-size: large; */
	border-radius: 10px;
	/* padding-left: 16px; */
}

.rc-picker .rc-time-picker-clear {
	display: none;
}

.rc-disabled-am-pm .rc-time-picker-panel-select {
	width: 14.9rem;
}
.left .rc-time-picker-panel-inner {
	right: 489px !important;
}

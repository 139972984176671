.ql-editor {
	min-height: 20vh;
}
.ql-toolbar {
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
}
.ql-container {
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
}
.switch-after {
	width: 12rem;
}
.switch-after input {
	border-radius: 1rem !important;
}
